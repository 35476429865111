import React from 'react'

const CreateRoleComponent = () => {
    return (
        <div className="container">
            <div className="card-body">
                <div className="col-sm-12">
                    <form className='border border-dark rounded p-3'>
                        <div className="form-group">
                            <label for="exampleInputPassword1">Role Name</label>
                            <input type="text" className="form-control" id="exampleInputPassword1" placeholder='enter Role Name' required />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateRoleComponent