import React, { useState, useEffect } from 'react';

import './DesignComponent.css'; // Custom styles
import Popup from './Modules/Popup';
import MediaEditPopup from './Modules/MediaPopup';
import TextMediaPopup from './Modules/TextMediaPopup';
import { CreateLayouthooks } from '../../Helper/LayoutHelper';
import { create } from 'xmlbuilder2';
import { toast } from 'react-toastify';
import { LayoutListhooks } from '../../Helper/LayoutHelper';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { GetTypeContentListhooks } from '../../Helper/ContentHelper';

const data = {
    resolution_type: '1080p( landscape )',
    intentWidth: '800',
    intentHeight: '450',
    scale: '0.41666666666667',
    mainWidth: '1920',
    mainHeight: '1080'
};

function parseLayoutXML(xmlString) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');

    // Extract the layout element
    const layoutElement = xmlDoc.querySelector('layout');
    const layout = {
        width: parseInt(layoutElement.getAttribute('width')),
        height: parseInt(layoutElement.getAttribute('height')),
        duration: parseInt(layoutElement.getAttribute('dur')),
    };

    // Extract regions
    const regionElements = xmlDoc.querySelectorAll('region');
    const regions = Array.from(regionElements).map(regionElement => {
        const region = {
            class: regionElement.getAttribute('class'),
            width: parseInt(regionElement.getAttribute('width')),
            height: parseInt(regionElement.getAttribute('height')),
            top: parseInt(regionElement.getAttribute('top')),
            left: parseInt(regionElement.getAttribute('left')),
            color: regionElement.getAttribute('color'),
            repeat: regionElement.getAttribute('repeat') === 'true',
            media: [],
        };

        // Extract media within each region
        const mediaElements = regionElement.querySelectorAll('media');
        region.media = Array.from(mediaElements).map(mediaElement => ({
            type: mediaElement.getAttribute('type'),
            name: mediaElement.getAttribute('name'),
            duration: parseInt(mediaElement.getAttribute('dur')),
            size: parseInt(mediaElement.getAttribute('size')),
            color: mediaElement.getAttribute('color'),
            behave: mediaElement.getAttribute('behave'),
            text: mediaElement.getAttribute('text'),
            id: mediaElement.getAttribute('id'),
        }));

        return region;
    });

    return { layout, regions };
}

var Images = [
    {
        "ContentId": 1,
        "Title": "Image 1",
        "Type": "Image",
        "FilePath": ""
    },
    {
        "ContentId": 2,
        "Title": "Image 2",
        "Type": "Image",
        "FilePath": ""
    },
    {
        "ContentId": 3,
        "Title": "Image 3",
        "Type": "Image",
        "FilePath": ""
    },
    {
        "ContentId": 4,
        "Title": "Image 4",
        "Type": "Image",
        "FilePath": ""
    },
]

var Videos = [
    {
        "ContentId": 5,
        "Title": "Video 1",
        "Type": "Video",
        "FilePath": ""
    },
    {
        "ContentId": 6,
        "Title": "Video 2",
        "Type": "Video",
        "FilePath": ""
    },
    {
        "ContentId": 7,
        "Title": "Video 3",
        "Type": "Video",
        "FilePath": ""
    },
    {
        "ContentId": 8,
        "Title": "Video 4",
        "Type": "Video",
        "FilePath": ""
    },
]

var Pdf = [
    {
        "ContentId": 9,
        "Title": "test.pdf",
        "Type": "PDF",
        "FilePath": ""
    }
]

const initialMediaItems = [
    { id: "1", type: "Image", content: "Image 1" },
    { id: "2", type: "Video", content: "Video 1" },
    { id: "3", type: "Text", content: "Text 1" },
    { id: "4", type: "Image", content: "Image 2" },
    { id: "5", type: "Video", content: "Video 2" },
];

function DesignComponent(props) {

    const [mediaItems, setMediaItems] = useState(initialMediaItems);
    const [resolution, setResolution] = useState(data);
    const [parsedLayout, setParsedLayout] = useState(null);
    const [CurrentRegionMedia, SetCurrentRegionMedia] = useState([]);
    const [mediaimages, Setmediaimages] = useState([]);
    const [mediavideos, Setmediavideos] = useState([]);
    const [mediapdf, Setmediapdf] = useState([]);
    const [currentpopupMedia, SetcurrentpopupMedia] = useState(null);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
    const [RegionIndex, SetRegionIndex] = useState(null);
    const [editingMedia, setEditingMedia] = useState(null);
    const [isTextPopupVisible, setIsTextPopupVisible] = useState(false); // New state for text popup
    const [textMedia, setTextMedia] = useState(null); // New state for text media  

    const [layoutData, setLayoutData] = useState({
        "layoutId": props.id,
        "name": '',
        "xmlDesign": "",
        "createdBy": null,
    });

    useEffect(() => {
        GetResolution();
    }, []);

    async function GetResolution() {
        getData(data);
    }

    async function getData(resolutions) {

        const data1 = await LayoutListhooks();
        console.log(data1);
        if (data1 !== undefined) {
            const parsedData = parseLayoutXML(data1[0].xmlDesign);
            setParsedLayout(parsedData);
            setLayoutData(data1[0]);
        }
        // Get Media 
        const imageMedia = await GetTypeContentListhooks('Image');
        Setmediaimages(imageMedia);
        const VideoMedia = await GetTypeContentListhooks('Video');
        Setmediavideos(VideoMedia);
        const pdfMedia = await GetTypeContentListhooks('PDF');
        Setmediapdf(pdfMedia);
    }


    if (!parsedLayout) {
        return <div>Loading...</div>;
    }

    const { layout, regions } = parsedLayout;



    const deleteRegion = (index) => {
        if (window.confirm("Are you sure you want to delete this region?")) {
            const updatedRegions = [...regions];
            updatedRegions.splice(index, 1);
            setParsedLayout((prevState) => ({
                ...prevState,
                regions: updatedRegions,
            }));
        }
    };

    const handleTimelineClick = (currentRegionIndex) => {
        console.log(currentRegionIndex)
        if (currentRegionIndex !== null) {
            const selectedRegion = regions[currentRegionIndex];
            debugger;
            SetCurrentRegionMedia(selectedRegion.media);
            SetRegionIndex(currentRegionIndex);
            console.log(selectedRegion.media);
            toast.success('Region Timeline Selected!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleImageButtonClick = (type) => {
        debugger;
        if (RegionIndex !== null) {
            if (type === 'Image') {
                SetcurrentpopupMedia(mediaimages)
            } else if (type === 'Video') {
                SetcurrentpopupMedia(mediavideos)
            } else if (type === 'PDF') {
                SetcurrentpopupMedia(mediapdf)
            }

            setIsPopupVisible(true);
        } else {
            toast.error('Please Select Region Timeline!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    const handlePopupClose = () => {
        setIsPopupVisible(false);
    };

    const handleImageSelect = (image) => {
        if (RegionIndex !== null) {
            const updatedRegions = [...regions];
            updatedRegions[RegionIndex].media.push({
                type: image.type,
                name: image.url,                
                duration: 10, // Example duration
                id: image.contentId
            });
            setParsedLayout((prevState) => ({
                ...prevState,
                regions: updatedRegions,
            }));
            //  SetCurrentRegionMedia(updatedRegions[RegionIndex].media);
            handleTimelineClick(RegionIndex);

        }
        setIsPopupVisible(false);
    };

    const handleMediaEdit = (mediaIndex) => {
        debugger;
        const mediaToEdit = CurrentRegionMedia[mediaIndex];
        setEditingMedia(mediaToEdit);
        setIsEditPopupVisible(true);
    };

    const handleTextMediaEdit = (mediaIndex) => {
        debugger;
        const media = CurrentRegionMedia[mediaIndex];
        if (media.type === 'Text') {
            setTextMedia({ ...media, index: mediaIndex });
            setIsTextPopupVisible(true);
        }
    };

    const handleEditSave = (updatedMedia) => {
        debugger;
        if (RegionIndex !== null) {
            const updatedRegions = [...regions];
            updatedRegions[RegionIndex].media = updatedRegions[RegionIndex].media.map(media =>
                media.id === updatedMedia.id ? updatedMedia : media
            );
            setParsedLayout((prevState) => ({
                ...prevState,
                regions: updatedRegions,
            }));
            SetCurrentRegionMedia(updatedRegions[RegionIndex].media);
            setIsEditPopupVisible(false);
        }
    };

    const handleEditPopupClose = () => {
        setIsEditPopupVisible(false);
    };

    const handleMediaDelete = (mediaIndex) => {
        if (window.confirm('Are you sure you want to delete this media item?')) {
            const updatedRegions = [...regions];
            updatedRegions[RegionIndex].media.splice(mediaIndex, 1);
            setParsedLayout((prevState) => ({
                ...prevState,
                regions: updatedRegions,
            }));
            SetCurrentRegionMedia(updatedRegions[RegionIndex].media);
        }
    };

    const handleTextButtonClick = () => {
        if (RegionIndex !== null) {
            setIsTextPopupVisible(true);
        } else {
            toast.error('Please Select Region Timeline!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleTextSave = (name, textContent, size, color, mediaId, behave) => {
        if (mediaId) {
            // Editing an existing text media
            const updatedMedia = [...CurrentRegionMedia];
            const updatedRegions = [...regions];
            const mediaIndex = updatedRegions[RegionIndex].media.findIndex((media) => media.id === mediaId);
            if (mediaIndex !== -1) {
                updatedRegions[RegionIndex].media[mediaIndex] = {
                    ...updatedRegions[RegionIndex].media[mediaIndex],
                    text: textContent,
                    size,
                    color,
                    name,
                    behave: behave,
                };

                setParsedLayout((prevState) => ({
                    ...prevState,
                    regions: updatedRegions,
                }));

                updatedMedia[mediaIndex] = {
                    ...updatedMedia[mediaIndex],
                    text: textContent,
                    size,
                    color,
                    name,
                    behave: behave,
                };
                SetCurrentRegionMedia(updatedMedia);
            }
        } else {
            // Adding new text media
            const newTextMedia = {
                type: 'Text',
                name,
                duration: 10,
                size,
                color,
                behave: 'Center',
                text: textContent,
                id: `text${Date.now()}`,
            };

            SetCurrentRegionMedia((prevState) => [...prevState, newTextMedia]);
            regions[RegionIndex].media = [...CurrentRegionMedia, newTextMedia];
        }
        setIsTextPopupVisible(false);
    };

    const saveLayout = async (e) => {
        const xmlDoc = create({ version: '1.0' })
            .ele('XML', { version: '0.0.1' })
            .ele('layout', { width: parsedLayout.layout.width, height: parsedLayout.layout.height, dur: parsedLayout.layout.duration });

        parsedLayout.regions.forEach(region => {
            const regionElement = xmlDoc.ele('region', {
                class: region.class,
                width: region.width,
                height: region.height,
                top: region.top,
                left: region.left,
                color: region.color,
                repeat: region.repeat
            });

            region.media.forEach(media => {
                regionElement.ele('media', {
                    type: media.type,
                    name: media.name,
                    dur: media.duration,
                    id: media.id,
                    size: media.size,
                    color: media.color,
                    behave: media.behave,
                    text: media.text
                }).txt(media.name);
            });
        });

        const xml = xmlDoc.end({ prettyPrint: true });
        layoutData.xmlDesign = xml;
        debugger;
        console.log(layoutData);
        var res = await CreateLayouthooks(layoutData);
        if (res !== undefined) {
            toast.success('Design Saved successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } else {
            toast.error('Design Saved Failed!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };



    // Handle reordering when an item is dragged and dropped
    const handleOnDragEnd = (result) => {
        debugger
        // Check if the destination exists
        if (!result.destination) return;

        // Copy the list of media items
        const items = Array.from(CurrentRegionMedia);

        // Reorder the list using splice
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Update the state with the reordered list
        SetCurrentRegionMedia(items);
        //setMediaItems(items);
        const updatedRegions = [...regions];
        regions[RegionIndex].media = items;
        setParsedLayout((prevState) => ({
            ...prevState,
            regions: updatedRegions,
        }));
    };



    return (
        <div className="container-fluid content-top-gap" style={{ minHeight: '600px' }}>

            <div className='container mb-2'>

                <div className="text-center">
                    <h4>{props.name !== null ? ' Layout Name - ' + props.name : ''}</h4>
                    <p><b>Layout Details - </b>Aspect Ratio: 16:9 | Background Color: Black | Resolution Type:
                        {resolution.resolution_type}
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-8">
                    <div className="container">
                        <div
                            id="layout"
                            layout_scale={resolution?.scale}
                            main_width={resolution.mainWidth}
                            main_height={resolution.mainHeight}
                            style={{
                                marginLeft: '1%',
                                position: 'relative',
                                width: resolution?.intentWidth + 'px',
                                height: resolution?.intentHeight + 'px',
                                backgroundColor: '#000000',
                            }}
                        >
                            {regions.map((region, index) => {

                                return (
                                    <>
                                        <div className="region ui-widget-content" style={{ width: '100%', height: '100%' }}>
                                            <div className="layoutbtnline">
                                                <button
                                                    className="laybtn module"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default action
                                                        e.stopPropagation(); // Stop event propagation
                                                        handleTimelineClick(index);
                                                    }}
                                                >
                                                    <i className="fa fa-window-restore fa-lg" aria-hidden="true"></i>
                                                </button>
                                                <button
                                                    className="laybtn option"
                                                    onClick={() => console.log('Options')}
                                                >
                                                    <i className="fa fa-bars fa-lg" aria-hidden="true"></i>
                                                </button>
                                                <button
                                                    className="laybtn close"
                                                    style={{ border: '2px solid black', padding: '5px' }}
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default action
                                                        e.stopPropagation(); // Stop event propagation
                                                        deleteRegion(index);
                                                    }}
                                                >
                                                    <i className="fa fa-trash fa-lg" aria-hidden="true"></i>
                                                </button>

                                            </div>

                                            <p className="res mt-5 p-2">
                                                {region.width}x{region.height}
                                            </p>
                                            {/* <h5 className='ml-4'>Current Media</h5> */}
                                            {region.media.map((media, mediaIndex) => (
                                                <div key={mediaIndex} className="media ml-4 ">
                                                    <p className='medianame'>{media.type} - {media.name}</p>

                                                </div>
                                            ))}
                                        </div>


                                    </>

                                );
                            })}
                        </div>
                    </div>
                    <div className='container-fluid content-top-gap'>
                        <div className='text-center'>

                            <button
                                className="btn btn-primary"
                                id="savepos"
                                onClick={saveLayout}
                            >
                                Save Layout
                            </button>
                            &nbsp;
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12 col-sm-6 mb-4">
                            <div className='top-ads'>
                                <h4 className="text-center">Modules</h4>
                                <button className='btn btn-primary ml-2 mt-2' onClick={() => { handleImageButtonClick('Image') }}>Image</button>
                                <button className='btn btn-primary ml-2 mt-2' onClick={() => { handleImageButtonClick('Video') }}>Video</button>
                                <button className='btn btn-primary ml-2 mt-2' onClick={handleTextButtonClick}>Text</button>
                                <button className='btn btn-primary ml-2 mt-2' onClick={() => { handleImageButtonClick('PDF') }}>Pdf</button>
                            </div>
                            <div className="top-ads">
                                {RegionIndex !== null ? <h4 className="text-center"> TimeLine</h4> : ''}

                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="mediaList">
                                        {(provided) => (
                                            <ul
                                                className="media-list"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                style={{ listStyleType: "none", padding: 0 }}
                                            >
                                                {CurrentRegionMedia.map(({ id, type, name, duration }, mediaIndex) => (
                                                    <Draggable key={String(id)} draggableId={String(id)} index={mediaIndex}>
                                                        {(provided) => (
                                                            <li
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    userSelect: "none",
                                                                    padding: "16px",
                                                                    margin: "0 0 8px 0",
                                                                    minHeight: "50px",
                                                                    backgroundColor: "#FFF",
                                                                    color: "#333",
                                                                    border: "1px solid #ccc",
                                                                    borderRadius: "4px",
                                                                    ...provided.draggableProps.style,
                                                                }}
                                                            >
                                                                <strong>{type}:</strong> {name} - {duration} Seconds &nbsp;&nbsp;&nbsp;&nbsp;
                                                                {type === 'Image' || type === 'Video' ?
                                                                    <button
                                                                        className="btn btn-warning mr-2"
                                                                        onClick={() => handleMediaEdit(mediaIndex)}
                                                                    >
                                                                        <i className="fa fa-edit fa-lg" aria-hidden="true"></i>
                                                                    </button>
                                                                    :
                                                                    <button
                                                                        className="btn btn-warning mr-2"
                                                                        onClick={() => handleTextMediaEdit(mediaIndex)}
                                                                    >
                                                                        <i className="fa fa-edit fa-lg" aria-hidden="true"></i>
                                                                    </button>
                                                                }

                                                                <button
                                                                    className="btn btn-danger"
                                                                    onClick={() => handleMediaDelete(mediaIndex)}
                                                                >
                                                                    <i className="fa fa-trash fa-lg" aria-hidden="true"></i>
                                                                </button>
                                                            </li>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </ul>
                                        )}
                                    </Droppable>
                                </DragDropContext>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {isPopupVisible && (
                <Popup
                    images={currentpopupMedia}
                    onSelect={handleImageSelect}
                    onClose={handlePopupClose}
                />
            )}{isEditPopupVisible && (
                <MediaEditPopup
                    media={editingMedia}
                    onSave={handleEditSave}
                    onClose={handleEditPopupClose}
                />
            )}
            {isTextPopupVisible && (
                <TextMediaPopup
                    onClose={() => setIsTextPopupVisible(false)}
                    onSave={handleTextSave}
                    media={textMedia} // Pass the text media being edited
                />
            )}
        </div>
    );
}

export default DesignComponent;
