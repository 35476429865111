import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import FacilityFormComponent from '../../Components/APITableData/FacilityFormComponent';

function FacilityForm(props) {
    let navigate = useNavigate();
    const params = useParams();

    return (
        <div>
            <FacilityFormComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );

}

export default FacilityForm