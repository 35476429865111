import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './Content.css';  // Ensure this path is correct
import service from '../../Services/service';
import { toast } from 'react-toastify';

const ContentCreateComponent = (props) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles);           
        }
    });

    const resetDropzone = () => {
        setFiles([]);        
        setUploadProgress(0);
    };

    const handleUpload = async () => {
        
        if (files.length === 0) {            
            toast.error('No files selected for upload!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        try {
            const response = await service.createorupdateFile("Content/CreateOrUpdate", formData, (progress) => {
                setUploadProgress(progress);
                toast.success('File uploaded successfully!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
                //  window.location.reload();
                setTimeout(() => {                    
                    resetDropzone();
                }, 3000);
            });
        } catch (error) {
            console.error("API Error during file upload:", error);            
            toast.error('File uploaded failed!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div>

            <div class="container-fluid content-top-gap">
                <nav aria-label="breadcrumb" class="mb-4">
                    <ol class="breadcrumb my-breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            {
                                props.id !== undefined ? 'Edit Layout' : 'Create Layout'
                            }
                        </li>
                    </ol>
                </nav>

                <div class="accordions">
                    <div class="row">

                        <div class="col-lg-12 mb-4">
                            <div class="card card_border">
                                <div class="card-header chart-grid__header">
                                    {
                                        props.id !== undefined ? 'Edit Content' : 'Create Content'
                                    }

                                </div>
                                <div class="card-body">
                                    <div class="accordion" id="accordionExample">
                                        <div class="card">
                                            <div class="card-header " id="headingOne">

                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-sm-2'>
                                                            <label>Select File:</label>
                                                        </div>
                                                        <div className='col-sm-10'>
                                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                                <input {...getInputProps()} />
                                                                <p>Drag 'n' drop some files here, or click to select files</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-sm-2'>
                                                            <label>Selected Files:</label>
                                                        </div>
                                                        <div className='col-sm-10'>
                                                            {files.length > 0 && (
                                                                <ul>
                                                                    {files.map(file => (
                                                                        <li key={file.name}>{file.name}</li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-sm-12'>
                                                            {uploadProgress > 0 ? <>{uploadProgress}%</> : null}
                                                            {uploadProgress > 0 && (
                                                                <div>
                                                                    <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
                                                                </div>
                                                            )}
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                                <button type="submit" onClick={handleUpload} className='btn btn-success'>Save</button>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentCreateComponent;
