import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ContentListComponent from '../../Components/ContentComponent.js/ContentListComponent';
function ContentList(props) {
    let navigate = useNavigate();
    const params = useParams();
    return (
        <div>
            <ContentListComponent {...props} navigate={navigate} id={params.id}  />
        </div>
    );

}

export default ContentList