function TopBar() {
    const handleLogout = () => {
        localStorage.clear();
        window.location.href = "/";

    }
    return (
        <div className="header sticky-header">

            <div className="menu-right">
                <div className="navbar user-panel-top">
                    <div className="search-box">
                        {/* <form action="#search-results.html" method="get">
                            <input className="search-input" placeholder="Search Here..." type="search" id="search" />
                            <button className="search-submit" value=""><span className="fa fa-search"></span></button>
                        </form> */}
                    </div>
                    <div className="user-dropdown-details d-flex">
                        <div className="profile_details_left">
                            
                        </div>
                        <div className="profile_details">
                            <ul>
                                <li className="dropdown profile_details_drop">
                                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" id="dropdownMenu3" aria-haspopup="true"
                                        aria-expanded="false">
                                        <div className="profile_img">
                                            <img src="assets/images/profileimg.jpg" className="rounded-circle" alt="" />
                                            <div className="user-active">
                                                <span></span>
                                            </div>
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu drp-mnu" aria-labelledby="dropdownMenu3">
                                        <li className="user-info">
                                            <h5 className="user-name">Admin</h5>
                                            <span className="status ml-2">User</span>
                                        </li>                                       
                                        <li className="logout"> <span className="ml-3" type="button" onClick={handleLogout} href="#sign-up.html"><i className="fa fa-power-off"></i> Logout</span> </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar