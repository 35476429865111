import React, { useState } from 'react'
import { Loginuser } from '../../Helper/LoginHelper'
import { toast } from 'react-toastify'

const LoginComponent = () => {
    const [inputField, setinputField] = useState([{
        email: "",
        password: "",
    }])

    const handleInput = (evt) => {
        const { name, value } = evt.target;
        setinputField((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleLoginData = async (evt) => {
        evt.preventDefault();
        var data = {
            ...inputField,
        }
        const res = await Loginuser(data);
        debugger
        if (res) {
            if (res.status === "Successfully") {
                localStorage.setItem('userId', res.id);
                localStorage.setItem('name', res.firstname + " " + res.lastname);
                localStorage.setItem('role', res.role);
                toast.success(`login${res.status}done`);
                window.location.href = "/";
            }
            else {
                toast.error(`${res.status}`);
            }
        }
    }

    return (
        <div className="container-fluid d-flex justify-content-center " style={{ minHeight: "100vh", marginTop: "15vh" }}>
            <div className="container d-flex justify-content-center" >
                <div className="row">
                    {/* <div className="col-sm-12 col-md-10  border border-success"> */}
                    <form onSubmit={handleLoginData}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email</label>
                            <input type="email" className="form-control" id="exampleInputEmail1" name='email' aria-describedby="emailHelp" placeholder="Enter email" onChange={handleInput} />
                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password</label>
                            <input type="password" className="form-control" id="exampleInputPassword1" name='password' placeholder="Password" onChange={handleInput} />
                        </div>
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default LoginComponent