import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './testing.css';  // Ensure this path is correct
import service from '../../Services/service';

const Testing1Component = () => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [files, setFiles] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles);
            setUploadError(null);
            setUploadSuccess(false);
        }
    });

    const handleUpload = async () => {
        debugger
        if (files.length === 0) {
            setUploadError('No files selected for upload');
            return;
        }

        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        });

        try {
            const response = await service.createorupdateFile("FileUpload/UploadFile", formData, (progress) => {
                setUploadProgress(progress);
                alert("File uploaded successfully");
                setUploadSuccess(true);
                window.location.reload();
            });
        } catch (error) {
            console.error("API Error during file upload:", error);
            setUploadError("File upload failed");
        }
    };

    // const handleUpload = async () => {
    //     if (files.length === 0) {
    //         setUploadError('No files selected for upload');
    //         return;
    //     }

    //     const formData = new FormData();
    //     files.forEach(file => {
    //         formData.append('files', file);
    //     });

    //     try {
    //         // Use XMLHttpRequest to get progress updates
    //         const xhr = new XMLHttpRequest();

    //         xhr.upload.onprogress = (event) => {
    //             if (event.lengthComputable) {
    //                 const progress = (event.loaded / event.total) * 100;
    //                 setUploadProgress(progress); // Update the progress state
    //             }
    //         };

    //         xhr.onload = () => {
    //             if (xhr.status === 200) {
    //                 alert("File uploaded successfully");
    //                 setUploadSuccess(true);
    //                 window.location.reload();
    //             } else {
    //                 console.error("Upload failed:", xhr.statusText);
    //                 setUploadError("File upload failed");
    //             }
    //         };

    //         xhr.onerror = () => {
    //             console.error("API Error during file upload");
    //             setUploadError("File upload failed");
    //         };

    //         xhr.open('POST', 'FileUpload/UploadFile', true);
    //         xhr.send(formData);
    //     } catch (error) {
    //         console.error("Unexpected Error during file upload:", error);
    //         setUploadError("File upload failed");
    //     }
    // };




    return (
        <div>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            {files.length > 0 && (
                <div>
                    <h4>Selected files:</h4>
                    <ul>
                        {files.map(file => (
                            <li key={file.name}>{file.name}</li>
                        ))}
                    </ul>
                </div>
            )}
            <button onClick={handleUpload}>Upload</button>
            {uploadProgress > 0 ? <>{uploadProgress}%</> : null}
            {uploadProgress > 0 && (
                <div>
                    <progress value={uploadProgress} max="100">{uploadProgress}%</progress>
                </div>
            )}
            {uploadError && <div className="error">{uploadError}</div>}
            {uploadSuccess && <div className="success">Files uploaded successfully!</div>}
        </div>
    );
};

export default Testing1Component;
