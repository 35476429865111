import React, { useState, useEffect } from 'react';
import service from '../../Services/service';
import { toast } from 'react-toastify';
import { GetAPIDataValue } from '../../Helper/APIDataHelper';

const StatusAPIDataComponent = (props) => {
    const [uploadProgress, setUploadProgress] = useState(0);
  //  const [status, setstatus] = useState('');

    const [property, setProperty] = useState(false); // Initial state

    useEffect(() => {
        getData();
    }, []);

    async function getData(status) {
        console.log('long submit' + status)
        const data = await GetAPIDataValue(status);
        if (data) {
            console.log(data);  
         
            if(data.value === "True"){
                setProperty(true);
            }else if(data.value === "False"){
                setProperty(false);
            }       
        }
    }

    const handlesubmit = async () => {
        console.log('long submit')
        if(property === true){           
            getData("True") 
        }else{            
            getData("False") 
        }
        toast.success('API Table Data Update Successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }); 
        
    };

    return (
        <div>

            <div class="container-fluid content-top-gap">
                <nav aria-label="breadcrumb" class="mb-4">
                    <ol class="breadcrumb my-breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            {
                                props.id !== undefined ? 'Enable Disable API Data' : 'Enable Disable API Data'
                            }
                        </li>
                    </ol>
                </nav>

                <div class="accordions">
                    <div class="row">

                        <div class="col-lg-12 mb-4">
                            <div class="card card_border">
                                <div class="card-header chart-grid__header">
                                    {
                                        props.id !== undefined ? 'Enable/Disable API Data' : 'Enable/Disable API Data'
                                    }

                                </div>
                                <div class="card-body">
                                    <div class="accordion" id="accordionExample">
                                        <div class="card">
                                            <div class="card-header " id="headingOne">


                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-sm-2'>
                                                            <label>Enable API Data:</label>
                                                        </div>
                                                        <div className='col-sm-2'>
                                                            
                                                                <input
                                                                    type="radio"
                                                                    value="true"
                                                                    checked={property === true}
                                                                    onChange={() => setProperty(true)}
                                                                /> &nbsp; 
                                                                True 
                                                            
                                                        </div>
                                                        <div className='col-sm-8'>
                                                            
                                                                <input
                                                                    type="radio"
                                                                    value="true"
                                                                    checked={property === false}
                                                                    onChange={() => setProperty(false)}
                                                                /> &nbsp; 
                                                                False 
                                                            
                                                        </div>
                                                    </div>
                                                </div>


                                                <button type="submit" onClick={handlesubmit} className='btn btn-success'>Save</button>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusAPIDataComponent;
