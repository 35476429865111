import service from "../Services/service";
export async function GetAPIDataValue(value) {
    var data;
    await service.getdata("IndustryFacility/UpdateAPIData?Value="+value).then((res) => {
        data = res.data;
    });
    return data;
}

export async function GetAllFacilityData() {
    var data;
    await service.getdata("IndustryFacility/GetAll").then((res) => {
        data = res.data;
    });
    return data;
}

export async function CreateOrUpdateFacility(formData) {
    var data;
    await service.createorupdate("IndustryFacility/CreateOrUpdate", formData)
        .then(res => {
            //  setApiResponse(res.data); // Set the API response in state  
            data = res.data;
        })
        .catch(error => {
            // Handle error if API call fails
            console.error("API Error:", error);

        });
    return data;
}