import React, { useState, useEffect } from 'react';
import { GetAllFacilityData } from '../../Helper/APIDataHelper';
import { CreateOrUpdateFacility } from '../../Helper/APIDataHelper';
import { toast } from 'react-toastify';
import './Facillty.css';

const FacilityForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        id:0,
        headers: [{
            nameOfIndustryFacility: '',
            dateOfUpdateOfDisplay: '',
            detailsOfUpdatedCtoandAuthorisationWithValidity: '',
            detailsOfOperationalStatus: '',
        }],
        airEmissions: [],
        effluentDischarges: [],
        hazardousWasteDetails: [],
        products: [],
    });

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        setLoading(true);
        const data = await GetAllFacilityData();
        if (data) {
            console.log(data);
            setFormData(data);
            setLoading(false);
        }
    }

    const addRow = (section) => {
        const newRow = {};
        switch (section) {
            case 'airEmissions':
                newRow.airEmissionSections = [{
                    sourceOfAirPollution: '',
                    airPollutionControlDeviceWithStackHeight: '',
                    monitoredData: '',
                    limitsStandardPrescribedBySpcbsCpcb: '',
                }];
                break;
            case 'effluentDischarges':
                newRow.effluentDischargeSections = [{
                    sourceOfEffluentDischargeWithQuantity: '',
                    treatmentMethod: '',
                    modeOfDisposalOfTreatmentEffluent: '',
                    effluentDischargeMonitorings: [{
                        inlet: '',
                        outlet: '',
                        discharge: '',
                    }],
                }];
                break;
            case 'hazardousWasteDetails':
                newRow.hazardousWasteValues = [{
                    typeOfHw: '',
                    generated: '',
                    stored: '',
                    disposed: '',
                    modeOfTreatment: '',
                }];
                break;
            case 'products':
                newRow.productManufactured = '';
                newRow.productValues = [{
                    detailsOfHazardous: '',
                    quantityOfStorage: '',
                    purpose: '',
                }];
                break;
            default:
                break;
        }
        setFormData({ ...formData, [section]: [...formData[section], newRow] });
    };

    const handleChange = (section, index, field, value, subIndex = 0, subField = null) => {
        const updatedData = { ...formData };
        if (section === 'headers') {
            updatedData[section][index][field] = value;
        } else if (subField) {
            updatedData[section][index][field][subIndex][subField] = value;
        } else {
            updatedData[section][index][field][subIndex] = value;
        }
        setFormData(updatedData);
    };

    const handleSubmit = async () => {
        //  onSubmit(formData);
        formData.id = 0;
        const data = await CreateOrUpdateFacility(formData);
        if(data.code === 200){
            getData();
            toast.success('Data Updated Successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    return (
        <div style={{minHeight:600}}>

            <div class="container-fluid content-top-gap">
                <nav aria-label="breadcrumb" class="mb-4">
                    <ol class="breadcrumb my-breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">
                            {
                                props.id !== undefined ? 'Facility Form' : 'Facility Form'
                            }
                        </li>
                    </ol>
                </nav>
                {loading && <div className="loader1">Loading...</div>}
                {!loading && ( 
                <div class="accordions">
                    <div class="row">

                        <div class="col-lg-12 mb-4">
                            <div class="card card_border">
                                <div class="card-header chart-grid__header">
                                    {
                                        props.id !== undefined ? 'Facility Form' : 'Facility Form'
                                    }

                                </div>
                                <div class="card-body">
                                    <div class="accordion" id="accordionExample">
                                        <div class="card">
                                            <div class="card-header " id="headingOne">

                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-sm-12'>
                                                            <h3>Header:</h3>
                                                        </div>
                                                        <div className='col-sm-12'>
                                                            {formData.headers.map((header, index) => (
                                                                <div key={index}>
                                                                    <div className='form-row'>
                                                                        <div className="form-group col-md-6">
                                                                            <label>Name of Industry/Facility:</label>
                                                                            <input type="text" class="form-control input-style" value={header.nameOfIndustryFacility} onChange={(e) => handleChange('headers', index, 'nameOfIndustryFacility', e.target.value)} />
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label>Date of Update of Display:</label>
                                                                            <input
                                                                                type="text"
                                                                                class="form-control input-style"
                                                                                placeholder="Date of Update of Display"
                                                                                value={header.dateOfUpdateOfDisplay}
                                                                                onChange={(e) => handleChange('headers', index, 'dateOfUpdateOfDisplay', e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label>Details of Updated CTO and Authorization with Validity:</label>
                                                                            <input
                                                                                type="text"
                                                                                class="form-control input-style"
                                                                                placeholder="Details of Updated CTO and Authorization with Validity"
                                                                                value={header.detailsOfUpdatedCtoandAuthorisationWithValidity}
                                                                                onChange={(e) => handleChange('headers', index, 'detailsOfUpdatedCtoandAuthorisationWithValidity', e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="form-group col-md-6">
                                                                            <label>Details of Operational Status:</label>
                                                                            <input
                                                                                type="text"
                                                                                class="form-control input-style"
                                                                                placeholder="Details of Operational Status"
                                                                                value={header.detailsOfOperationalStatus}
                                                                                onChange={(e) => handleChange('headers', index, 'detailsOfOperationalStatus', e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                {formData.products.map((row, index) => (
                                                    <div className='form-group' key={index}>
                                                        <div className='row'>
                                                            <div className='col-sm-12'>
                                                                <h3>Products:</h3>
                                                            </div>
                                                            <div className='col-sm-12'>
                                                                <div className='form-row'>
                                                                    <div className="form-group col-md-6">
                                                                        <label>Product Manufactured:</label>
                                                                        <input
                                                                            type="text"
                                                                            class="form-control input-style"
                                                                            placeholder="Product Manufactured"
                                                                            value={row.productManufactured}
                                                                            onChange={(e) => handleChange('products', index, 'productManufactured', e.target.value)}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='form-group'>
                                                            <div className='row'>
                                                                <div className='col-sm-12'>
                                                                    <h3>Product Values:</h3>
                                                                </div>
                                                                <div className='col-sm-12'>
                                                                    {row.productValues.map((productValue, subIndex) => (
                                                                        <div key={subIndex}>
                                                                            <div className='form-row'>
                                                                                <div className="form-group col-md-4">
                                                                                    {subIndex === 0 && (
                                                                                        <label>Details of Hazardous:</label>
                                                                                    )}
                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Details of Hazardous"
                                                                                        value={productValue.detailsOfHazardous}
                                                                                        onChange={(e) => handleChange('products', index, 'productValues', e.target.value, subIndex, 'detailsOfHazardous')}
                                                                                    />

                                                                                </div>
                                                                                <div className="form-group col-md-4">
                                                                                    {subIndex === 0 && (
                                                                                        <label>Quantity of Storage:</label>
                                                                                    )}

                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Quantity of Storage"
                                                                                        value={productValue.quantityOfStorage}
                                                                                        onChange={(e) => handleChange('products', index, 'productValues', e.target.value, subIndex, 'quantityOfStorage')}
                                                                                    />

                                                                                </div>
                                                                                <div className="form-group col-md-4">
                                                                                    {subIndex === 0 && (
                                                                                        <label>Purpose:</label>
                                                                                    )}

                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Purpose"
                                                                                        value={productValue.purpose}
                                                                                        onChange={(e) => handleChange('products', index, 'productValues', e.target.value, subIndex, 'purpose')}
                                                                                    />


                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-sm-12'>
                                                            <h3>Hazardous Waste Details:</h3>
                                                        </div>
                                                        <div className='col-sm-12'>
                                                            {formData.hazardousWasteDetails.map((row, index) => (
                                                                <div key={index}>
                                                                    {row.hazardousWasteValues.map((subrow, subindindex) => (
                                                                        <div key={subindindex}>
                                                                            <div className='form-row'>
                                                                                <div className="form-group col-md-3">
                                                                                    {subindindex === 0 && (
                                                                                        <label>Type of HW:</label>
                                                                                    )}

                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Type of HW"
                                                                                        value={subrow.typeOfHw}
                                                                                        onChange={(e) => handleChange('hazardousWasteDetails', index, 'hazardousWasteValues', e.target.value, subindindex, 'typeOfHw')}
                                                                                    />
                                                                                </div>
                                                                                <div className="form-group col-md-2">

                                                                                    {subindindex === 0 && (
                                                                                        <label>Generated:</label>
                                                                                    )}
                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Generated"
                                                                                        value={subrow.generated}
                                                                                        onChange={(e) => handleChange('hazardousWasteDetails', index, 'hazardousWasteValues', e.target.value, subindindex, 'generated')}
                                                                                    />

                                                                                </div>
                                                                                <div className="form-group col-md-2">
                                                                                    {subindindex === 0 && (
                                                                                        <label>Stored:</label>
                                                                                    )}

                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Stored"
                                                                                        value={subrow.stored}
                                                                                        onChange={(e) => handleChange('hazardousWasteDetails', index, 'hazardousWasteValues', e.target.value, subindindex, 'stored')}
                                                                                    />

                                                                                </div>
                                                                                <div className="form-group col-md-2">
                                                                                    {subindindex === 0 && (
                                                                                        <label>Disposed:</label>
                                                                                    )}

                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Disposed"
                                                                                        value={subrow.disposed}
                                                                                        onChange={(e) => handleChange('hazardousWasteDetails', index, 'hazardousWasteValues', e.target.value, subindindex, 'disposed')}
                                                                                    />
                                                                                </div>
                                                                                <div className="form-group col-md-3">

                                                                                    {subindindex === 0 && (
                                                                                        <label>Mode of Treatment:</label>
                                                                                    )}
                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Mode of Treatment"
                                                                                        value={subrow.modeOfTreatment}
                                                                                        onChange={(e) => handleChange('hazardousWasteDetails', index, 'hazardousWasteValues', e.target.value, subindindex, 'modeOfTreatment')}
                                                                                    />
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-sm-12'>
                                                            <h3>Air Emissions:</h3>
                                                        </div>
                                                        <div className='col-sm-12'>
                                                            {formData.airEmissions.map((row, index) => (
                                                                <div key={index}>
                                                                    {row.airEmissionSections.map((subrow, subindindex) => (
                                                                        <div key={subindindex}>
                                                                            <div className='form-row'>
                                                                                <div className="form-group col-md-3">
                                                                                    {subindindex === 0 && (
                                                                                        <label>Source of Air Pollution:</label>
                                                                                    )}

                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Source of Air Pollution"
                                                                                        value={subrow.sourceOfAirPollution}
                                                                                        onChange={(e) => handleChange('airEmissions', index, 'airEmissionSections', e.target.value, subindindex, 'sourceOfAirPollution')}
                                                                                    />

                                                                                </div>
                                                                                <div className="form-group col-md-4">

                                                                                    {subindindex === 0 && (
                                                                                        <label>Air Pollution Control Device with Stack Height:</label>
                                                                                    )}
                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Air Pollution Control Device with Stack Height"
                                                                                        value={subrow.airPollutionControlDeviceWithStackHeight}
                                                                                        onChange={(e) => handleChange('airEmissions', index, 'airEmissionSections', e.target.value, subindindex, 'airPollutionControlDeviceWithStackHeight')}
                                                                                    />

                                                                                </div>
                                                                                <div className="form-group col-md-2">
                                                                                    {subindindex === 0 && (
                                                                                        <label>Monitored Data:</label>
                                                                                    )}

                                                                                    <input
                                                                                    readOnly
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Monitored Data"
                                                                                        value={subrow.monitoredData}
                                                                                        onChange={(e) => handleChange('airEmissions', index, 'airEmissionSections', e.target.value, subindindex, 'monitoredData')}
                                                                                    />



                                                                                </div>
                                                                                <div className="form-group col-md-3">
                                                                                    {subindindex === 0 && (
                                                                                        <label>Limits Standard Prescribed by SPCBs/CPCB:</label>
                                                                                    )}

                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Limits Standard Prescribed by SPCBs/CPCB"
                                                                                        value={subrow.limitsStandardPrescribedBySpcbsCpcb}
                                                                                        onChange={(e) => handleChange('airEmissions', index, 'airEmissionSections', e.target.value, subindindex, 'limitsStandardPrescribedBySpcbsCpcb')}
                                                                                    />


                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='form-group'>
                                                    <div className='row'>
                                                        <div className='col-sm-12'>
                                                            <h3>Effluent Discharges:</h3>
                                                        </div>
                                                        <div className='col-sm-12'>
                                                            {formData.effluentDischarges.map((row, index) => (
                                                                <div key={index}>
                                                                    <div className='form-row'>
                                                                        <div className="form-group col-md-4">
                                                                            {index === 0 && (
                                                                                <label>Source of Effluent Discharge with Quantity:</label>
                                                                            )}
                                                                            <input
                                                                                type="text"
                                                                                class="form-control input-style"
                                                                                placeholder="Source of Effluent Discharge with Quantity"
                                                                                value={row.effluentDischargeSections[0].sourceOfEffluentDischargeWithQuantity}
                                                                                onChange={(e) => handleChange('effluentDischarges', index, 'effluentDischargeSections', e.target.value, 0, 'sourceOfEffluentDischargeWithQuantity')}
                                                                            />


                                                                        </div>


                                                                        <div className="form-group col-md-4">
                                                                            {index === 0 && (
                                                                                <label>Treatment Method:</label>
                                                                            )}
                                                                            <input
                                                                                type="text"
                                                                                class="form-control input-style"
                                                                                placeholder="Treatment Method"
                                                                                value={row.effluentDischargeSections[0].treatmentMethod}
                                                                                onChange={(e) => handleChange('effluentDischarges', index, 'effluentDischargeSections', e.target.value, 0, 'treatmentMethod')}
                                                                            />



                                                                        </div>
                                                                        <div className="form-group col-md-4">
                                                                            {index === 0 && (
                                                                                <label>Mode of Disposal of Treatment Effluent:</label>
                                                                            )}

                                                                            <input
                                                                                type="text"
                                                                                class="form-control input-style"
                                                                                placeholder="Mode of Disposal of Treatment Effluent"
                                                                                value={row.effluentDischargeSections[0].modeOfDisposalOfTreatmentEffluent}
                                                                                onChange={(e) => handleChange('effluentDischarges', index, 'effluentDischargeSections', e.target.value, 0, 'modeOfDisposalOfTreatmentEffluent')}
                                                                            />


                                                                        </div>
                                                                    </div>
                                                                    {row.effluentDischargeSections[0].effluentDischargeMonitorings.map((monitoring, subindindex) => (
                                                                        <div key={subindindex}>
                                                                            <div className='form-row'>
                                                                                <div className="form-group col-md-4">
                                                                                    {subindindex === 0 && (
                                                                                        <label>Inlet:</label>
                                                                                    )}
                                                                                    <input
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Inlet"
                                                                                        value={monitoring.inlet}
                                                                                        onChange={(e) => handleChange('effluentDischarges', index, 'effluentDischargeSections', e.target.value, subindindex, 'inlet')}
                                                                                    />
                                                                                </div>
                                                                                <div className="form-group col-md-4">

                                                                                    {subindindex === 0 && (
                                                                                        <label>Outlet:</label>
                                                                                    )}

                                                                                    <input
                                                                                    readOnly
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Outlet"
                                                                                        value={monitoring.outlet}
                                                                                        onChange={(e) => handleChange('effluentDischarges', index, 'effluentDischargeSections', e.target.value, subindindex, 'outlet')}
                                                                                    />

                                                                                </div>
                                                                                <div className="form-group col-md-4">
                                                                                    {subindindex === 0 && (
                                                                                        <label>Discharge:</label>
                                                                                    )}
                                                                                    <input
                                                                                    readOnly
                                                                                        type="text"
                                                                                        class="form-control input-style"
                                                                                        placeholder="Discharge"
                                                                                        value={monitoring.discharge}
                                                                                        onChange={(e) => handleChange('effluentDischarges', index, 'effluentDischargeSections', e.target.value, subindindex, 'discharge')}
                                                                                    />
                                                                                </div>
                                                                                </div>


                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                                <button onClick={handleSubmit} type="submit" className='btn btn-success'>Save</button>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>
)}

            </div>
        </div>
    );
};

export default FacilityForm;
