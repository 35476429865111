import HomeComponent from '../Components/HomeComponent/HomeComponent'
import LoginComponent from '../Components/LoginComponent/LoginComponent';
import Testing1Component from '../Components/TestingComponent/Testing1Component';
import CreateRole from '../Pages/Main/Role/CreateRole';
import RoleList from '../Pages/Main/Role/RoleList';
import CreateUser from '../Pages/Main/User/CreateUser';
import UserList from '../Pages/Main/User/UserList';
import LayoutList from '../Pages/ContentLayout/LayoutList';
import DesignLayout from '../Pages/ContentLayout/DesignLayout';
import DemoLayout from '../Components/LayoutComponent/DemoLayout';
import ContentList from '../Pages/Content/ContentList';
import CreateContent from '../Pages/Content/CreateContent';
import UpdateAPIData from '../Pages/StatusAPIData/UpdateAPIData';
import FacilityForm from '../Pages/StatusAPIData/FacilityForm';


const routes = [
    { path: '/', name: 'Home', component: HomeComponent, element: HomeComponent, exact: true },
    { path: '/Login', name: 'Login', component: LoginComponent, element: LoginComponent, exact: true },
    { path: '/CreateUser', name: 'CreateUser', component: CreateUser, element: CreateUser, exact: true },
    { path: '/EditUser/:id', name: 'CreateUser', component: CreateUser, element: CreateUser, exact: true },
    { path: '/CreateRole', name: 'CreateRole', component: CreateRole, element: CreateRole, exact: true },
    { path: '/RoleList', name: 'RoleList', component: RoleList, element: RoleList, exact: true },
    { path: '/testing1', name: 'Testing', component: Testing1Component, element: Testing1Component, exact: true },
    { path: '/UserList', name: 'UserList', component: UserList, element: UserList, exact: true },
    { path: '/LayoutList', name: 'LayoutList', component: LayoutList, element: LayoutList, exact: true },
    { path: '/Design/:id', name:'DesignLayout', component: DesignLayout, element: DesignLayout, exact: true},
    { path: '/Demo', name: 'DemoLayout', component: DemoLayout, element: DemoLayout, exact: true },
    { path: '/ContentList', name: 'ContentList', component: ContentList, element: ContentList, exact: true },
    { path: '/CreateContent', name: 'CreateContent', component: CreateContent, element: CreateContent, exact: true },
    { path: '/EnableAPIData', name: 'UpdateAPIData', component: UpdateAPIData, element: UpdateAPIData, exact: true },
    { path: '/FacilityForm', name: 'FacilityForm', component: FacilityForm, element: FacilityForm, exact: true },
    
]

export default routes;