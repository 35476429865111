// src/components/LayoutList.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LayoutListhooks } from '../../Helper/LayoutHelper';

const LayoutListComponent = () => {
    // State to hold the layout data, filters, and pagination
    const [layouts, setLayouts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('Name');
    const [sortDescending, setSortDescending] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(0);



    // Fetch data when the component mounts or any dependencies change
    useEffect(() => {
        getData();
    }, []);

    async function getData() {      
        
        const data = await LayoutListhooks();
        if (data) {
            console.log(data);
           // console.log(data.items);
            setLayouts(data);         
        }
    }
    return (
        <div>
            <div class="container-fluid content-top-gap">
                <nav aria-label="breadcrumb" class="mb-4">
                    <ol class="breadcrumb my-breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Layout List</li>
                    </ol>
                </nav>

                <div class="accordions">
                    <div class="row">

                        <div class="col-lg-12 mb-4">
                            <div class="card card_border">
                                <div class="card-header chart-grid__header">
                                    Layout List
                                </div>
                                <div class="card-body">
                                    <div class="accordion" id="accordionExample">
                                        
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Layout ID</th>
                                                    <th>Name</th>
                                                    {/* <th>Resolution</th> */}
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {layouts.map((layout) => (
                                                    <tr key={layout.layoutId}>
                                                        <td>{layout.layoutId}</td>
                                                        <td>{layout.name}</td>
                                                        {/* <td>{layout.resolutionType}</td> */}
                                                        <td>                                                            
                                                            &nbsp; <Link  to={'/EditLayout/'+layout.layoutId} className="btn btn-danger"> <i className="fa fa-edit fa-lg" aria-hidden="true"></i></Link> 
                                                            {/* &nbsp; <Link  to={`/Design/${layout.layoutId}&${layout.name}`}  className="btn btn-primary"> <i className="fa fa-desktop fa-lg" aria-hidden="true"></i></Link> */}
                                                            &nbsp; <Link  to={`/Design/${layout.layoutId}?name=${layout.name}`}  className="btn btn-primary"> <i className="fa fa-desktop fa-lg" aria-hidden="true"></i></Link> 
                                                            &nbsp; <Link to={'/Demo'} className='bnt btn-primary'><i className="fa fa-desktop fa-lg" aria-hidden="true"></i></Link> 
                                                            
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default LayoutListComponent;