import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ContentListhooks } from '../../Helper/ContentHelper';

function ContentListComponent() {
    // State to hold the content data, filters, and pagination
    const [contentData, setContent] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('Name');
    const [sortDescending, setSortDescending] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalitems, setTotalitems] = useState(0);

    // Fetch data when the component mounts or any dependencies change
    useEffect(() => {
        getData(searchTerm, sortBy, sortDescending, pageNumber, pageSize);
    }, [searchTerm, sortBy, sortDescending, pageNumber, pageSize]);

    async function getData(searchTerm, sortBy, sortDescending, pageNumber, pageSize) {

        const data = await ContentListhooks(searchTerm, sortBy, sortDescending, pageNumber, pageSize);

        if (data) {
            console.log(data.items);
            setContent(data.items);
            setTotalPages(data.totalPages);
            setTotalitems(data.totalItems)
        }
    }



    // Handle the change of sorting options
    const handleSortChange = (event) => {
        const [field, order] = event.target.value.split('_');
        setSortBy(field);
        setSortDescending(order === 'desc');
    };

    // Handle page change
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPageNumber(newPage);
        }
    };

    return (
        <div>
            <div class="container-fluid content-top-gap">
                <nav aria-label="breadcrumb" class="mb-4">
                    <ol class="breadcrumb my-breadcrumb">
                        <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Content List</li>
                    </ol>
                </nav>

                <div class="accordions">
                    <div class="row">

                        <div class="col-lg-12 mb-4">
                            <div class="card card_border">
                                <div class="card-header chart-grid__header">
                                    Content List
                                </div>
                                <div class="card-body">
                                    <div class="accordion" id="accordionExample">
                                        <div class="card">
                                            <div class="card-header " id="headingOne">
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <input type="text" className='form-control input-style' placeholder="Search by name..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <select className='form-control input-style' onChange={handleSortChange} value={`${sortBy}_${sortDescending ? 'desc' : 'asc'}`}>
                                                            <option value="Name_asc">Name (A-Z)</option>
                                                            <option value="Name_desc">Name (Z-A)</option>
                                                            <option value="CreatedAt_asc">Created At (Oldest)</option>
                                                            <option value="CreatedAt_desc">Created At (Newest)</option>
                                                            <option value="UpdatedAt_asc">Updated At (Oldest)</option>
                                                            <option value="UpdatedAt_desc">Updated At (Newest)</option>
                                                            <option value="CreatedBy_asc">Created By (A-Z)</option>
                                                            <option value="CreatedBy_desc">Created By (Z-A)</option>
                                                        </select>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Content ID</th>
                                                    <th>Type</th>
                                                    <th>Name</th>
                                                    <th>Created By</th>
                                                    <th>Created On</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {contentData.map((content) => (
                                                    <tr key={content.contentId}>
                                                        <td>{content.contentId}</td>
                                                        <td>{content.type}</td>
                                                        <td>{content.filePath}</td>
                                                        <td>Admin</td>
                                                        <td>{content.createdAt}</td>
                                                        {/* <td>
                                            <button className="btn btn-warning"> <i className="fa fa-trash fa-lg" aria-hidden="true"></i></button>&nbsp;
                                            <Link to={'/EditContent/' + content.contentId} className="btn btn-danger"> <i className="fa fa-edit fa-lg" aria-hidden="true"></i></Link>  &nbsp;
                                            <Link to={`/Design/${content.contentId}?name=${content.title}`} className="btn btn-primary">
                                                <i className="fa fa-desktop fa-lg" aria-hidden="true"></i></Link>

                                        </td> */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <div className='text-center'>
                                            <button className='btn btn-primary' onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                                                Previous
                                            </button>
                                            <span>Page {pageNumber} of {totalPages}</span>
                                            <button className='btn btn-primary' onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                                                Next
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default ContentListComponent