import React, { useEffect, useState } from "react";
import { GetAllUserList, GetUserById, UserCreateOrUpdate } from "../../Helper/UsersHelper";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const UserListComponet = () => {
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState({});
    useEffect(() => {
        UserList();
    }, []);

    const getUser = async (id) => {
        const res = await GetUserById(id);
        if (res.userId) {
            setUserData(res);
            if (res.isActive === true) {
                var data = {
                    ...res,
                    userId: res.userId,
                    isActive: false,
                }
            }
            else {
                data = {
                    ...res,
                    userId: res.userId,
                    isActive: true,
                }
            }
            debugger
            const remove = await UserCreateOrUpdate(data)
            if (remove.code === 200) {
                if (data.isActive === true) {
                    toast.success("user Activated succesfully");
                    UserList();
                }
                else {
                    toast.success("user mark In Active Succesfully");
                    UserList()
                }
            }
            else {
                toast.error("some error occured");
            }
        }
    }

    const UserList = async () => {
        const res = await GetAllUserList();

        if (res !== undefined) {
            setData(res);
            console.log(res);
        }
    };

    return (
        <div>
            <div class="container-fluid content-top-gap">
                <nav aria-label="breadcrumb" class="mb-4">
                    <ol class="breadcrumb my-breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="index.html">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            User List
                        </li>
                    </ol>
                </nav>

                <div class="accordions">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div class="card card_border">
                                <div class="card-header chart-grid__header">User List</div>
                                <div class="card-body">
                                    <div class="accordion" id="accordionExample">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th> Email</th>
                                                    <th>First Name</th>
                                                    <th>LastName</th>
                                                    <th>Role</th>
                                                    <th>IsActive</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.email}</td>
                                                        <td>{item.firstName}</td>
                                                        <td>{item.lastName}</td>
                                                        <td>{item.role}</td>
                                                        <td>{item.isActive === true ? "Active" : "NotActive"}</td>

                                                        <td>
                                                            {item.isActive === true ? <button className="btn btn-warning" onClick={() => {
                                                                getUser(item.userId);
                                                            }}>
                                                                {" "}
                                                                <i
                                                                    className="fa fa-trash fa-lg"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button> : <button className="btn btn-success" onClick={() => {
                                                                getUser(item.userId);
                                                            }}>
                                                                {" "}
                                                                <i class="fa fa-check" aria-hidden="true"></i>
                                                            </button>}
                                                            &nbsp;{" "}
                                                            <Link
                                                                to={"/EditUser/" + item.userId}
                                                                className="btn btn-danger"
                                                            >
                                                                {" "}
                                                                <i
                                                                    className="fa fa-edit fa-lg"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </Link>
                                                            {/* &nbsp; <Link  to={`/Design/${layout.layoutId}&${layout.name}`}  className="btn btn-primary"> <i className="fa fa-desktop fa-lg" aria-hidden="true"></i></Link> */}
                                                            {/* &nbsp;{" "} */}
                                                            <Link
                                                            // to={`/Design/${layout.layoutId}?name=${layout.name}`}
                                                            // className="btn btn-primary"
                                                            >
                                                                {/* {" "}
                                  <i
                                    className="fa fa-desktop fa-lg"
                                    aria-hidden="true"
                                  ></i> */}
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserListComponet;