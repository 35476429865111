import { Link } from "react-router-dom";
function LeftBar() {

    return (
        <div className="sidebar-menu sticky-sidebar-menu">
            <div className="logo">
                <h1><a href="index.html">Bronx</a></h1>
            </div>
            <div className="logo">
                <Link to='/'>
                    <img src="/assets/images/logo.png" alt="Your logo" title="Your logo" className="img-fluid" style={{ height: "35px" }} />
                </Link>

            </div>
            <div className="logo-icon text-center">
                <a href="/" title="logo"><img src="/assets/images/logo.png" alt="logo-icon" /> </a>
            </div>
            <div className="sidebar-menu-inner">
                <ul className="nav nav-pills nav-stacked custom-nav">
                    <li className="active">
                        <Link to='/'> <i className="fa fa-tachometer"></i><span> Dashboard</span> </Link>

                    </li>
                    <li className="menu-list">
                        <a href="#"><i className="fa fa-cogs"></i>
                            <span>Layout <i className="lnr lnr-chevron-right"></i></span></a>
                        <ul className="sub-menu-list">
                            <li>
                                <Link to='/LayoutList'>Layout List</Link>
                            </li>

                        </ul>
                    </li>
                    <li className="menu-list">
                        <a href="#"><i className="fa fa-medium"></i>
                            <span>Content <i className="lnr lnr-chevron-right"></i></span></a>
                        <ul className="sub-menu-list">
                            <li><Link to='/ContentList'>Content List</Link>     </li>
                            <li><Link to='/CreateContent'>Create New Content</Link> </li>
                        </ul>
                    </li>
                    <li><Link to='/EnableAPIData'><i className="fa fa-table"></i> <span>Enable API Data</span></Link></li>
                    <li><Link to='/FacilityForm'><i className="fa fa-table"></i> <span>Update Table Data</span></Link></li>
                    <li className="menu-list">
                        <a href="#"><i className="fa fa-cogs"></i>
                            <span>Users <i className="lnr lnr-chevron-right"></i></span></a>
                        <ul className="sub-menu-list">
                            <li><Link to='/UserList'>User List</Link>     </li>
                            <li><Link to='/CreateUser'>Create New User</Link> </li>
                        </ul>
                    </li>
                    {/* <li><Link to='/RoleList'><i className="fa fa-file-text"></i> <span>Roles</span></Link></li> */}
                    <li><a href="blocks.html"><i className="fa fa-th"></i> <span>Support</span></a></li>

                </ul>
                {/* <a className="toggle-btn">
                    <i className="fa fa-angle-double-left menu-collapsed__left"><span>Collapse Sidebar</span></i>
                    <i className="fa fa-angle-double-right menu-collapsed__right"></i>
                </a> */}
            </div>
        </div>
    );
}
export default LeftBar