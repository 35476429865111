import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GetUserById, UserCreateOrUpdate } from '../../Helper/UsersHelper';

const CreateUserComponent = (props) => {
    const [countries, setCountries] = useState([]);
    const [inputField, setinputField] = useState({
        userId: 0,
        firstName: "",
        lastName: "",
        isActive: true,
        role: "",
        email: "",
        password: "",
    });
    const [hidden, setHidden] = useState(false);
    const handleUserData = (evt) => {
        const { name, value } = evt.target;
        setinputField((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        debugger
    }

    useEffect((evt) => {
        getUser(props.id);
    }, [])

    const getUser = async (id) => {
        if (id) {
            setHidden(true);
        }
        const res = await GetUserById(id);
        debugger
        if (res.userId) {
            setinputField(res);
        }
    }

    const saveUserDetails = async (evt) => {
        evt.preventDefault();
        const data = {
            ...inputField,
        }
        console.log(data);
        debugger
        const res = await UserCreateOrUpdate(data);
        if (res.code === 200) {
            if (props.id) {
                toast.success("user updated successfully");
            }
            else {

                toast.success("user saved successfully");
            }
        }
        else {
            toast.error("Error while saving user");
        }
    }

    return (

        <div>

            <div className="container-fluid content-top-gap">
                <nav aria-label="breadcrumb" className="mb-4">
                    <ol className="breadcrumb my-breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">
                            User Details
                        </li>
                    </ol>
                </nav>

                <div className="accordions">
                    <div className="row">

                        <div className="col-lg-12 mb-4">
                            <div className="card card_border">
                                <div className="card-header chart-grid__header">

                                    Signup User Details
                                </div>
                                <div className="card-body">
                                    <div className="accordion" id="accordionExample">
                                        <div className="card">

                                            <div className="card-header " id="headingOne">
                                                <form onSubmit={saveUserDetails} method="post">
                                                    <div className="form-row">

                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="inputEmail4" className="input__label">First Name</label>
                                                            <input type="text" className="form-control input-style"
                                                                placeholder="First Name" name='firstName' value={inputField.firstName} onChange={handleUserData} required />
                                                        </div>
                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="lastname" className="input__label">Last Name</label>
                                                            <input type="text" className="form-control input-style" id="lastname"
                                                                placeholder="Last Name" name='lastName' value={inputField.lastName} onChange={handleUserData} required />
                                                        </div>

                                                        <div className="form-group col-md-4">
                                                            <label htmlFor="inputPassword" className="input__label">Password</label>
                                                            <input type="password" className="form-control input-style" value={inputField.password} placeholder='Password123@' id="inputPassword" name='password' onChange={handleUserData} required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail" className="input__label">Email</label>
                                                            <input type="email" className="form-control input-style"
                                                                placeholder="Email" name='email' onChange={handleUserData} value={inputField.email} required />
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputCountry" className="input__label">Role</label>
                                                            <select id="selectElement" className="form-control input-style" value={inputField.role} name='role' onChange={handleUserData} required>
                                                                <option >Choose...</option>
                                                                <option >Admin</option>
                                                                <option >SubAdmin</option>

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-style mt-4">{props.id ? "Update" : "Sign Up"}</button>
                                                </form>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    )
}

export default CreateUserComponent