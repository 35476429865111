import service from "../Services/service";


export async function UserCreateOrUpdate(userDetails) {
    var data;
    await service.createorupdate("userController/CreateOrUpdate", userDetails).then((res) => {
        if (res !== undefined) {
            data = res.data;
        }
        else {
            data = null;
        }
    });
    return data;
}

export async function GetAllUserList() {
    var data;
    await service.getdata("userController/GetAll").then((res) => {
        if (res !== undefined) {
            data = res.data;
        }
        else {
            data = null;
        }
    });
    return data;
}

export async function GetUserById(userId) {
    var data;
    await service.getdatabyId("userController/GetById?id=", userId).then((res) => {
        if (res !== undefined) {
            data = res.data;
        }
        else {
            data = null;
        }
    });
    return data;
}



