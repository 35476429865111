
import Content from './Pages/Layout/Content';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useRef } from 'react'
import LoginComponent from './Components/LoginComponent/LoginComponent';
function App() {

  const id = localStorage.getItem("userId")
  const myDivRef = useRef(null);

  useEffect(() => {
    // Delay script addition by 10 milliseconds
    const timer = setTimeout(() => {
      const script = document.createElement('script');
      script.src = `${process.env.PUBLIC_URL}/assets/js/scripts.js`;// Replace with your script URL
      script.async = true;
      document.body.appendChild(script);

      // Clean up script when component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }, 3000); // 10ms delay

    // Cleanup timeout if component unmounts before script is added
    return () => clearTimeout(timer);
  }, []);
  return (

    <div ref={myDivRef}>{id === undefined || !id ? <LoginComponent /> : <Content />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        bodyClassName="toastBody"
        theme="colored"
      />
    </div>
  );
}

export default App;