import service from "../Services/service";
export async function GetTypeContentListhooks(type) {
    var data;
    await service.getdata("Content/GetAllContentType?Type="+type).then((res) => {
        data = res.data;
    });
    return data;
}
export async function ContentListhooks(searchTerm, sortBy, sortDescending, pageNumber, pageSize) {
    var data;
    await service.getdata("Content/GetAll?searchTerm=" + searchTerm + "&sortBy=" + sortBy + "&sortDescending=" + sortDescending + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize).then((res) => {
        data = res.data;
    });
    return data;
}

