import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import StatusAPIDataComponent from '../../Components/APITableData/StatusAPIDataComponent';

function UpdateAPIData(props) {
    let navigate = useNavigate();
    const params = useParams();

    return (
        <div>
            <StatusAPIDataComponent {...props} navigate={navigate} id={params.id} />
        </div>
    );

}

export default UpdateAPIData